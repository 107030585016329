.login-form-body{
    max-width: 750px;
}
.modern-view-layout{

    .bg-holder{
        left: 4px;
        bottom: -2px;
    }
}
.dashboard-revenue-card{
    .bg-card{
        background-size: cover;
    }
}

