.sf-scrollbar {
    visibility: hidden;
    overflow: auto;
    width: 100%;
    &:hover, &:focus {
        visibility: visible;
    }
    .sf-scrollbar-content {
        visibility: visible;
    }
}