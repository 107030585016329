.page-secondary-sidebar{
    .card-header {
        padding: 1.5rem 0.8rem 0.8rem;
    }
    .list-group-item{
        @include padding-none;   
        @include flex-size(0, 0,100%); 
        
        > .nav-link.child {
            font-size: .95rem;
        }
        
    }
    .nav-link.dropdown-indicator {
        padding: .9rem 0rem .9rem;
    }
   
}