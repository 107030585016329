$bootstrap-blue: #08c;

#reportrange, .daterangepicker {
    background-color: $white !important;
    color: $dark !important;
}
.daterangepicker li:hover, .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: $bootstrap-blue !important;
}
.calendar-table {
    background-color: $white !important;
    // color: $dark;
}
.weekend.off.ends.available, .off.ends.available{
    background-color: $gray-500 !important;
    color: $dark !important;
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{
    border: solid $dark !important;
    border-width: 0 2px 2px 0 !important;
}