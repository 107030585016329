.notification_body {
    max-height: calc(100vh - 11rem);
    overflow: auto;
}
.notification-indicator::before {
    &:not(.commerceFlow--notification-indicator::before) {
        right: .625rem;
        top: -.4rem;
    }
    
}
