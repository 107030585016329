@mixin flex-size($grow, $shrink,$basis) {
    -webkit-box-flex: $grow $shrink $basis;      /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: $grow $shrink $basis; ;         /* OLD - Firefox 19- */
    -webkit-flex: $grow $shrink $basis; ;          /* Chrome */
    -ms-flex: $grow $shrink $basis; ;              /* IE 10 */
    flex: $grow $shrink $basis; ;     
    
}

@mixin max-min-full-with{
    
    max-width: 100%!important;
    min-width: 100%!important;
}
@mixin padding-none {
    padding: 0px 0px 0px;  
    
}
@mixin boxshadow {
    -webkit-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

@mixin offset-margin($margin, $value){
    margin-#{$margin}: $value;
  }


@mixin offset-margin-genrator(){

    @for $i from 1 through 5 {
        @each $margin in t, l, b, r {
      
            @if $margin == t {

                .m#{$margin}-xs-#{$i}{
                    @include offset-margin('top', 1rem * $i) 
                }
              
               
            }
            @else if($margin == b){

                .m#{$margin}-xs-#{$i}{
                    @include offset-margin('bottom', 1rem * $i) 
                }
            }

            @else if($margin == l){

                .m#{$margin}-xs-#{$i}{
                    @include offset-margin('left', 1rem * $i) 
                }
            }
            @else if($margin == r){

                .m#{$margin}-xs-#{$i}{
                    @include offset-margin('right', 1rem * $i) 
                }
            }
          
        }
    
    }
}



