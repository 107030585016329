//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.



// Custom var

$preloader_dash: #e2e1eb;
$table_hover_clr: #f7f9fb;

$feild_border:  #e1e5eb;
$link_active_clr: #2c7be5;
