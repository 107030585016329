
.sf_dummy_Body {
  color: black;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0, .3);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 999999;
  font-size: 4.2rem;
  color: #333;
  text-align: center;
  line-height: 90vh;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes loading_Blink {
  50%{opacity: 0.4;}
  100%{opacity: 1;}
}
@keyframes loading_Blink {
  50%{opacity: 0.4;}
  100%{opacity: 1;}
}
.loading_Animation {
  -webkit-animation: loading_Blink 1.4s ease infinite !important;
          animation: loading_Blink 1.4s ease infinite !important;
  opacity: 1;
}


// Import wire load
.overlay-wire {
  position: fixed;
  top: 0;
  width: 100%;
  right: 0;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background-color: rgba($white, .3);
}
.overlay-wire img {
  width: 100px;
}

@-webkit-keyframes moving-gradient {
  0% {
      background-position: -250px 0;
 }
  100% {
      background-position: 250px 0;
 }
}

.sf-listing-placeholder {
    height: 12px;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, color-stop(20%, #eee), color-stop(50%, #ddd), color-stop(80%, #eee));
    background: -o-linear-gradient(left, #eee 20%, #ddd 50%, #eee 80%);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    -webkit-animation-name: moving-gradient;
            animation-name: moving-gradient;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
