
a:hover,a:focus{
    outline: none;
}
.tab .nav-tabs{
    border: none;
    border-radius: 30px;
    // box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
}
.tab .nav-tabs li {
    float: none;
    display: inline-block;
    position: relative;
    margin: .135rem;
}
.tab .nav-tabs li a{
    background: none;
    color: $gray-700;
    border: none;
    padding: 5px 15px;
    border-radius: 50px;
    transition: all 0.5s ease 0s;
}
// .tab .nav-tabs li a:hover{
//     background: $warning;
//     color: #fff;
//     border: none;
// }
.tab .nav-tabs li a.active,
.tab .nav-tabs li a.active:focus,
.tab .nav-tabs li a.active:hover{
    border: none;
    background: #748194;
    color: #fff;
}
.related-module-fancy-tab{
    overflow: hidden;
}
#more_related_list_items .dropdown-item {
    display: block !important;
}
.more-related-list-items-dropdown:not(.show) {
    display: none;
}

// .tab .tab-content{
//     font-size: 14px;
//     color: #686868;
//     line-height: 25px;
//     text-align: left;
//     padding: 5px 20px;
// }
// .tab .tab-content h3{
//     font-size: 22px;
//     color: #5b5a5a;
// }
@media only screen and (max-width: 480px){
    .tab .nav-tabs{
        border-radius: 10px;
    }
    .tab .nav-tabs li{
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }
}
