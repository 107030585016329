// Variables

$min_width_th: 200px;
$max_width_th: 200px;
$min_width_td: 200px;
$max_width_td: 200px;


// Style

.theme-revamp-table {
    &.sf-view-list-height {
        tbody {
            display: block;
        }
        tr {
            display: table;
            width: 100%;
            table-layout: inherit;
        }
        tbody+tbody  {
            border: 0 !important;
        }
        
    }
    &.table {
        margin-bottom: 1rem;
    }
    thead {
        background-color: $white;
        th {
            background-color: $white !important;
        }
    }
    th {
        min-width: $min_width_th !important;
        cursor: default;
        line-height: 19px;
        background-color: #fff;
        width: auto !important;
        max-width: $max_width_th !important;
        text-transform: capitalize;
    }
    tr {
        th {
            text-align: left !important;
            text-transform: capitalize;
        }
        td {
            text-align: left !important;
            min-width: $min_width_th !important;
            max-width: $max_width_th !important;
        }
    }
    &.table-hover tbody tr:hover {
        background-color: $table_hover_clr !important;
    }
    .t-actions-sm-btns {
        min-width: 20px!important;
        max-width: 20px !important;
        width: 20px !important;
        &--rel_list {
            min-width: 60px!important;
            max-width: 60px!important;
            width: 60px!important;
        }
    }
    .t-actions-sm-btns:nth-child(2) {
        min-width: 50px!important;
        max-width: 50px!important;
        width: 50px!important;
        text-align: center !important;
    }
    
}
.report-table-div {
    .theme-revamp-table {
        th {
            min-width: 230px!important;
        }
    }
}

.verticaly-middle--td {
    td {
        vertical-align: middle !important;
    }
}

.import-summary-table.dataTable {
    thead th:first-child {
        min-width: 100px;
    }
    thead th {
        min-width: 200px;
    }
}
table thead th {
    text-transform: capitalize;
}
#dash_views {
    .tbl-body {
        background-color: $white !important;
        td {
            a {
                text-align: left;
            }
        }
    }
}