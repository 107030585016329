/*-----------------------------------------------
|   Calender
-----------------------------------------------*/
.calendar {
  width: 3.125rem;
  line-height: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: $font-family-sans-serif;
  border-radius: $border-radius-soft;

  .calendar-month {
    background-color: $calendar-color;
    color: $white;
    border-radius: $border-radius-soft $border-radius-soft 0 0;
    padding: map_get($spacers, 1);
    font-weight: $font-weight-semi-bold;
  }

  .calendar-day {
    font-size: map_get($font-sizes, 3);
    padding: map_get($spacers, 1);
    border: 0.125rem solid rgba($calendar-color, 0.3);
    border-top: 0;
    border-radius: 0 0 $border-radius-soft $border-radius-soft;
    color: $gray-500;
    font-weight: $font-weight-bold;
  }
}
