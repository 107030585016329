.tooltip .tooltip-inner .migNotifyEmailDesc h5{
    color: #fff;
}
.tooltip .tooltip-inner .migNotifyEmailDesc svg{
    width: auto !important;
}
.svg-inline--fa.fa-w-16 {
    width: auto !important;
}



/*-------Toaster------*/
#toast-container {
    z-index: 999999999 !important;
}
.tooltip {
    z-index: 9999999 !important;
    pointer-events: none;
}
.note-editor .tooltip {
    top: -55px!important;
}
.modal {
    .note-editor .tooltip {
        top: -40px!important;
        left: -15px !important;
    }
}
/*==================*/

#signature-form {
    .note-editor .tooltip {
        top: -15px !important;
    }
}