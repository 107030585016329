//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.


/*-----------------------------------------------
|   light Theme Styles
-----------------------------------------------*/
.navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content {
    //max-width: 14.5rem !important;
    padding: 0.5rem 1rem 0 1rem !important;
    // height: auto;
    max-height: 100vh;
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-margin-right: 0px;
}


.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #748194ed;
    color: white;
}

#modern-pricing-wrapper{
    p, h1, h2, h3, h4 , h5, h6, small, label, th, td {
        color: #1a1b25 !important;
    }
    
    .sectionHeading {
        color: #1a1b25;
    }

    .pricingWizardState{
        background:#f6f8fa;
    }
}
