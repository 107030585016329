body:not(.modal-open){
    .navbar-top {
        z-index: 1039;
    }

    .main{
        .navbar-vertical {
            z-index: 1040 !important;
        }
    }
}

.navbar-top {
    padding-left: 15px !important;
}

