html {
	scroll-behavior: smooth;
}
.navbar-top {
    font-weight: 500 !important;
}
input::-ms-reveal,
      input::-ms-clear {
        display: none !important;
      }


/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    cursor: pointer;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar {
    background: #ccc;
  }
    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

//   div:hover::-webkit-scrollbar, .table-responsive:hover::-webkit-scrollbar{
//       height:10px
//     }

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: $link_active_clr;
}
a,.link-primary, .btn-link {
    color: $link_active_clr;
}
a:hover {
    color: $link_active_clr;
}
.report-link svg path {
    fill: $gray-700;
}


.grid-stack {
	>.grid-stack-item {
		>.grid-stack-item-content {
			left: 0 !important;
			right: 0 !important;
		}
	}
}

.grid-stack>.grid-stack-item[data-gs-x="0"]>.grid-stack-item-content {
    right: 10px !important;
    padding-right: 0.19rem !important;
}
.grid-stack>.grid-stack-item[data-gs-x="6"]>.grid-stack-item-content {
    padding-left: 0.19rem !important;
}
.grid-stack-item[data-gs-width="12"] >.grid-stack-item-content, form .grid-stack>.grid-stack-item:nth-child(odd)[data-gs-width="12"]>.grid-stack-item-content {
    right: 0 !important;
}
.grid-stack>.grid-stack-item>.grid-stack-item-content:not(.grid-stack>.enable_scroll>.grid-stack-item-content ){
    overflow-y:hidden !important;
}
svg.icons {
	width: 10px;
	height: 10px;
}


.col-2 {
    .navbar-vertical {
        .navbar-collapse {
            .navbar-vertical-content {
                height: $content_height;
            }
            .sf-view-list-height {
                height: calc(100vh - 13rem);
            }
        }
    }
}
.col-w-0 {
    width: $w-0 !important;
}

.dropdown-toggle:after { content: none }

.moduleOptionsDropdown .dropdown-menu a
{
    font-size: 0.8rem !important;
}

.sidebar-filter-options {
    position: absolute;
    left: 0;
    bottom: 0;
    background: $white;
    padding: 1rem;
    text-align: center;
    z-index: 9;
}

.list-group .list-group-item label {
    margin: 0;
}
.sticky-100 {
    top: 100px;
}
label.form-control{
    color: $gray-700;
    font-weight: 400 !important;
}
/*--------------Checkbox---------------------*/

.custom-control.custom-checkbox .custom-control-label{
    cursor: pointer;
}

/*-------------------------------------------*/
.required-icon {
    color: $danger;
}
.d-align-c {
    display: flex;
    align-items: center;
}

.dashboard-strip-connect {
    .profile {
        .photo {
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                box-shadow: 0px 1px 3px 1px #ddd;
            }
        }
    }
}
.fileinput-exists {
    overflow: hidden;
    margin-top: 2px !important;
}
select.list-group {
    padding: 0;
}

.btn-eclipse_w15 {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.navbar-vertical.navbar-expand-xl .navbar-collapse {
    margin-top: 0 !important;
}
.pe-none {
    pointer-events: none !important;
}
li.nav-item.pe-none {
    pointer-events: unset !important;
    cursor: not-allowed;
}
#theme-detail-view {
    .table-responsive {
        overflow-y: hidden;
    }
}
#view_list {
    .content.pb-5 {
        padding-bottom: 0 !important;
    }
    #sfListViewColWrapper {
        .card {
            padding-bottom: 0 !important;
            height: calc(100vh - 12.5rem);
        }

        .table-responsive{
            max-height: calc(100vh - 21.1rem);
        }
    }
}
.paginationlinks {
    ul.pagination {
        margin: 0;
    }
}

span#push_left:not(span[onclick]), span#up:not(span[onclick]), span#down:not(span[onclick]), span#push_right:not(span[onclick]) {
    color: $dark !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $gray-700 !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.nav-item.category:hover > .delete-category.pointer {
  display: inherit !important;
}
.overflow-x-auto{
    overflow-x: auto;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.overflow-y-auto{
    overflow-y: auto;
}
.overflow-y-hidden{
    overflow-y: hidden;
}

// .dropdown-menu:not(.dropdown-menu-right) {
//     left: unset !important;
//     transform: unset !important;
//     right: 100% !important;
// }
.c_card {
    -webkit-box-shadow: 0 0px 5px 0 #ddd;
    box-shadow: 0 0px 5px 0 #ddd;
}
.border_1 {
    border: 1px solid #eee;
}
.required_star {
    color: red;
}

#report-table {
    & > thead > tr > th {
        min-width: 300px;
        text-align: left;
    } 
}
.d-flex.d-none{ 
    display: none !important;
  }
.card.card-notification {
    min-width: 20rem;
}
.note-toolbar .note-btn, .note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
    background: inherit !important;
    color: inherit !important;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset:hover, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select:hover, .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset:hover, .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select:hover {
    background: inherit !important;
}

.multiselect__tags {
    background: inherit !important;
}
.multiselect__content-wrapper {
    background-color: $white !important;
    color: $gray-700 !important;
}
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
    background-color: $white !important;
}
.td-3 span {
    height: 12px;
    background: linear-gradient(to right, $gray-300 20%, $gray-100 50%, $gray-300 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
#emailsCardBody {
    .td-3 span {
        height: 15px;
    }
}
.alert {
    p {
        margin: 0;
    }
}
#create-lookup-modal-content {
    .row {
        margin: 0;
        .card {
            box-shadow: none;
        }
    }
}
#lookup-modal-body {
    td:first-child, th:first-child {
        width: 50px !important;
        min-width: 50px !important;
    }
    td a {
        text-align: left;
    }
}
.overflow-visible {
    overflow: visible !important;
}

#stripe-card-number, #stripe-card-expiry, #stripe-card-cvc {
    border: 1px solid $feild_border;
    border-radius: 4px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-left: 10px;
    height: calc(1.5em + 0.625rem + 2px)!important;
  }


.select2:not(.select2-hidden-accessible) {
    border: 1px solid $feild_border !important;
    height: calc(2.09375rem + 3px) !important;
    background-color: $feild_border !important;
    width: 100%;
    border-radius: 4px;
}
.page-secondary-sidebar .card-header{
    padding: 1.25rem;
}
.item-active-dot {
    right: 0;
    top: 9px;
}