/*-----------------------------------------------
|   common Variables
-----------------------------------------------*/
$content_height: calc(100vh - 8.3125rem);
$w-0: 0%;
$delete-btn:rgb(255, 0, 0);


/*-----------------------------------------------
|   Components Styles
-----------------------------------------------*/
@import "components/_mixin";
@import "components/scrollbar";
@import "components/tour-modal";
@import "components/timelineLogs";
@import "components/preloader";
@import "components/detailPage";
@import "components/modal";
@import "components/footer";
@import "components/statuses";
@import "components/import";

@import "components/select2";
@import "components/single-sign-on";
@import "components/datatable";
@import "components/form";
@import "components/buttons";
@import "components/dropdown";
@import "components/tooltip";
@import "components/tabs";
@import "components/notifications";


@import "components/classic-reports";
@import "components/table";
@import "components/_payment-gateways";
@import "components/_emails";
@import "components/_deals";
@import "components/_modules-fields";
@import "components/_banking";
@import "components/_workflow";
@import "components/_notes";
@import "components/_high-charts";
@import "components/_date-picker";
@import "components/_secondary-sidebar";
@import "components/_chat";
@import "components/_cards";
@import "components/_navbar";
@import "components/_global-responsive";


















/*-----------------------------------------------
|   General Styles
-----------------------------------------------*/
@import "components/general";



/*-----------------------------------------------
|   Responsive Styles
-----------------------------------------------*/
@import "components/responsive";