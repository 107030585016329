@media screen and (max-width: 1450px) {


    .dashboard-revenue-card{
        .card-title{
            font-size: 1rem;
        }
        .fs-3 {
            font-size: 1.328rem;
        }
    }
    .h-customization-sidebar{

        .hview-box{
            .hview-head,span{
                font-size: .8rem;
            }
            .scheck-text,
            .choose_module.form-control{
                font-size:.9rem;
            }
        } 
        
    }
  
}
@media only screen and  (max-width: 1700px) and  (min-width: 1200px)  {
    .page-secondary-sidebar{

        @include flex-size(0, 0,23%);
        max-width: 23%;
        .navbar-vertical.navbar-expand-xl.navbar-card{
            @include max-min-full-with;

            .navbar-collapse{
                @include max-min-full-with;
            }
        }

        .navbar-vertical-content.perfect-scrollbar.scrollbar {
            @include max-min-full-with;
        }

        .navbar-vertical {
            z-index: 7 !important;
        }
    }
    .page-secondary-content{
        @include flex-size(0, 0,77%);
        max-width: 77%;
    }

    
}
@media only screen and (max-width: 1650px) and (min-width: 1200px)  {

    .h-customization-sidebar{
        @include flex-size(0, 0,23%);
        max-width: 23%;
        
    }

    .h-customization-content{
        @include flex-size(0, 0,77%);
        max-width: 77%;
    }

}

@media screen and (max-width: 1250px) {


    .grid-stack{
        .grid-stack-item.Net.Calculations{
            .card-deck{
                .card{
                    .fs-4 {
                        font-size: 1.5rem;
                    }
                }
    
            } 
        }
    }
}
@media screen and (max-width: 1200px) {

    @include offset-margin-genrator;

    .more_related_list_items_container {
        #more_related_list_items {
            height: 300px;
            overflow-y: auto;
        }
    }
    .dashboard-revenue-card{
        .card{
            @include flex-size(0, 0, calc(33.33% - 1rem));
            margin-bottom: 1rem;
        }
        
    }
    body:not(.modal-open){
        .navbar-top {
            z-index: 1039;
        }
    
        .main{
            .navbar-vertical {
                z-index: 1040 !important;
            }
        }
    }
    .h-customization-sidebar 
    {

        .customization-secondary-sidebar {
            min-height: auto !important;

            .choose_chart_components{
                .hview-box.hview-accordion {
                    border-bottom: 0px solid #e5e5e5;
                }
            }
            


        }
    }
    .navbar-vertical.navbar-expand-xl{

        + .content {
            padding-top: 5.313rem;
        }
    } 
  
}

@media screen and (max-width: 991px) {

    #detail_info_top_right_btns {
        margin: 16px 0px 0px;
        flex: 0 0 100%;
    }

    .mobile-mb-0{
        margin-bottom:0px!important;
    }
    .mobile-mt-0{
        margin-top:0px!important;
    }
    .mobile-ml-0{
        margin-left:0px!important;
    }
    .mobile-mr-0{
        margin-right:0px!important;
    }

    .order-subscription-btn {
        flex-direction: column;
        justify-content: center;
        text-align: center;
       .btn {
            max-width: 300px;
            width: 100%;
            margin: 10px 0px 0px;
          }
    }


}
@media screen and (max-width: 810px) {
    .grid-stack{
        .grid-stack-item.Net.Calculations{

            /*.card.h-100{
                 -webkit-box-shadow: 0 0px 0px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
                 box-shadow: 0 0px 0px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
            }*/
        

            .card-deck{
                .card{
             
                        @include flex-size(0, 0, 100%);
                    .fs-4 {
                        font-size: 1.5rem;
                    }
                }
    
            } 
            .grid-stack-item-content:not(.grid-stack > .enable_scroll > .grid-stack-item-content){
                overflow-y: auto !important;
                border-radius: 5px;
                /* @include boxshadow;
                 background: #fff;*/
            }
    
        }
    }
    
}
@media screen and (max-width: 769px) {

    .dashboard-revenue-card{
        .card{
            @include flex-size(0, 0, calc(50% - 1rem));
            margin-bottom: 1rem;
        }
    }
  
}
@media screen and (min-width: 992px) {

    .page-secondary-sidebar{
    
    .card-body {
        max-height: calc(100vh - 10.5rem);
        overflow-y: auto;
    }
    .sidebar-sticky-card{
        top:95px;
    }
    
  }

}
@media screen and (min-width: 420px) {

   
  
}
