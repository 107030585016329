.integration-box-item, .single-integration-img-wrapper{
    background-color: $white !important;
}
.payment-gateway-tile{
    width: 190px;
    height: 140px;
    position: relative;
    text-align: center;
    margin: 20px 5px 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    cursor: pointer;
    border-radius: 3px;
    background-color: #fff;
    //border: 1px solid #d7d7d9;
    //-webkit-box-shadow: 0 1px 0 #e8e8e9;
    //box-shadow: 0 1px 0 #e8e8e9;
    .gateway-img-top{
        width: 70%;
        height: auto;
        border: none;
        position: absolute;
        top: 48%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .gateway-description-text{
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 82px 16px 0;
    }
}
//.payment-gateway-tile:hover{
//    background-color:#b6c1d2;
//}


/*************************
*
* Nift Pay     **********
*
************************/
.niftPay-wrapper {
    div, strong,h2,h5, td {
        color: #ffffff;
    }
    .btn_Process_Card#__btnProcessCard__, .btn_Process_Acct#__btnProcessAcct__, .btn_Process_Wallet#__btnProcessWallet__ {
        font-weight: 600;
        line-height: 1.5;
        padding: 0 1.25rem;
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: center;
        border-radius: 0.25rem;
        color: #fff !important;
        border-color: #5e72e4 !important;
        background-color: #5e72e4 !important;
        font-size: .875rem;
        position: relative;
        transition: all .15s ease;
        letter-spacing: .025em;
        text-transform: none;
        margin-top: 20px;
    }
    .btn_Process_Acct#__btnProcessAcct__, .btn_Process_Wallet#__btnProcessWallet__ {
        margin-top: 30px;
    }
    // .pgwhpccardelemscontainer > div > iframe {
    //     text-align: left;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     flex: 1 1 100%;
    //     margin-bottom: 10px;
    // }
    .pgwhpccardelemscontainer > div:not(:nth-child(4)), .pgwhpcacctelemscontainer > div, .pgwhpcwalletcontainer > div{
        text-align: left;
        flex: 1 1 100%;
        input, select {
            width: 90%;
            border-radius: 5px;
            outline: unset;
        }
    }
    .pgwhpccardelemscontainer > div > iframe {
        margin: 5px !important;
    }
    .pgwhpccardelemscontainer > div:nth-child(4) {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
    .pgwhpccardelemscontainer {
        display: flex;
        flex-wrap: wrap;
    }
    .pgwhpccardelemscontainer > div label, .pgwhpcacctelemscontainer > div label, .pgwhpcwalletcontainer > div label {
        width: 100%;
    }
    .pgwhpccardelemscontainer, .pgwhpcacctelemscontainer, .pgwhpcwalletcontainer {
        &> div {
            input {
                border-radius: 5px;
            }
        }
    }
    .pgwhpccardelemscontainer > div:nth-child(4) input {
        width: 44% !important;
    }
    .pgwhpccardelemscontainer > div:nth-child(4) label {
        text-align: left;
    }
    .public_page_table.table-bordered th, .public_page_table.table-bordered td {
        border: 0;
        word-break: break-all;
        white-space: inherit;
        padding: 0.5rem;
    }

    .public_page_table.table-bordered th {
        background-color: #ffffff;
    }

    .public_page_table.table-bordered thead, .public_page_table.table-bordered tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .public_page_table.table-bordered tbody{
        display: block;
        max-height: 300px;
        overflow-y: auto;
    }
    .public_page_table.table-bordered th:not(:first-child), .public_page_table.table-bordered td:not(:first-child) {
        width: 120px;
    }
}
@media screen and (min-width: 1200px) {
    .min-vh-100_md {
        min-height: 100vh !important;
    }
}
@media screen and (max-width: 480px) {
    .niftPay-wrapper .pgwhpccardelemscontainer>div:not(:nth-child(4)) input {
        width: 94%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1540px) {
    .niftPay-wrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 96.33333%;
        flex: 0 0 96.33333%;
        max-width: 96.33333%;
    }
}

@media screen and (min-width:768px) {
    .public_page_table.table-bordered tr:not(:last-child) {
        border-bottom: 1px solid #ffffff;
    }
}

@media screen and (max-width: 768px) {
    .niftPay-wrapper {
        .col-md-12 .col-md-12, .p_0_xs {
            padding: 0 !important;
        }
        .public_page_table.table-bordered tbody {
            max-height: unset;
        }
        .text_wrap_normal {
            white-space: normal !important;
        }
        .mb-2_xs {
            margin-bottom: 1.5rem;
        }
        .m-2_xs {
            margin: 1.5rem;
        }
        .d_flex_xs {
            display: flex !important;
        }
        .px_0_xs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        }
        .tr_shadow.d_block_xs {
        border-top: 1px solid #ffffff;
        margin-bottom: 15px;
        }
        .tr_shadow.d_block_xs th {
        margin: 0;
        }
        .d_block_xs {
        display: block;
        }
        table.table_fixed {
        border-collapse: unset;
        }
        .tr_shadow {
            box-shadow: 0 3px 15px 0px #495bc5;
        }
        /*  Enable this to hide header
        */
        .hide_th_xs {
        border: 10px solid;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        padding: 0;
        position: absolute;
        width: 1px;
        }
        .border_b_tr_xs {
        border-bottom: 1px solid #ffffff;
        display: block;
        }
        .tr_subtotal_xs {
        border-top: 1px solid #ffffff;
        display: block;
        }
        /*
        .mat-table .mat-row:nth-child(even) {background: #CCC}
        .mat-table .mat-row:nth-child(odd) {background: #FFF}
        */
        .public_page_table.table-bordered .td_xs {
        border: 1px solid #ffffff;
        display: block;
        text-align: right;
        font-weight: bold !important;
        min-height: 30px;
        /* width: 60vw; */
        border-top: 0 !important;
        }
        .td_xs:before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        text-align: left;
        text-transform: uppercase;
        font-weight: normal;

        font-size: 0.9rem;
        display: flex;
        align-items: center;
        flex: 1 1 70%;
        }
        .td_xs:last-child {
        border-bottom: 0;
        }
        .td_xs:first-child {
            margin-top: 0;
        }

        .tr_shadow.d_block_xs > td:first-child::before {
        display: none;
        }
        .tr_shadow.d_block_xs > td:first-child {
        display: block !important;
        text-align: left;
        }
        .public_page_table.table-bordered th, .public_page_table.table-bordered td {
        width: auto !important;
        }
    }

}

