.detail-notes {
    .edit-note-form{
        margin: 10px;
    }
    .notes-table{
        background-color: #eff2f4;
        outline-style: solid;
        outline-color: #eff2f4;
        outline-width: 10px;
        margin: 10px;
    }
    .gray-text{
        color: #a2a2a2;
    }
    .change-color:hover{
        color: #0a0c0d;
    }

    .notes-content{
        white-space: pre-line;
    }
}