// @media (min-width: 1200px) {
// 	.navbar-vertical.sf-listview-sidebar.navbar-expand-xl.navbar-card {
// 		padding: 0;
// 		max-width: 100% !important;
// 		.navbar-collapse {
// 			width: 100% !important;
// 		}
// 		.navbar-vertical-content {
// 			width: 100% !important;
// 		}
// 	}
// 	.navbar-vertical.navbar-expand-xl.navbar-vibrant {
// 		padding: 0;
// 		max-width: 100% !important;
// 		.navbar-collapse {
// 			width: 100% !important;
// 		}
// 		.navbar-vertical-content {
// 			width: 100% !important;
// 		}
// 	}
// 	.navbar-vertical.navbar-expand-xl.navbar-inverted {
// 		padding: 0;
// 		max-width: 100% !important;
// 		.navbar-collapse {
// 			width: 100% !important;
// 		}
// 		.navbar-vertical-content {
// 			width: 100% !important;
// 		}
// 	}
// 	.add_pay_method_modal {
// 		height: 80px;
// 	}
// }
// .desktop-display {
// 	display: block;
// }
// .mobile-display {
// 	display: none;
// }

// @media screen and (min-width:1200px) and (max-width:1280px) {
// 	.navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content {
// 		padding: 0.5rem!important;
// 	}
// }

@media screen and (min-width:1200px) and (max-width:1780px) {
	.settings-currency--table {
		table-layout: fixed;
		td.t-link-btns > .btn {
			margin-block: 2px;
		}
	}
}

@media screen and (min-width:1200px) and (max-width:1280px) {
	.navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content, .navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content {
		padding: 0.5rem!important;
	}
}

@media screen and (min-width:1200px) and (max-width:1780px) {
	.settings-currency--table {
		table-layout: fixed;
		td.t-link-btns > .btn {
			margin-block: 2px;
		}
	}
}

@media (max-width: 1200px) {

	// hide module
	/*a[href*="home-customization"] {
		display: none !important;
	}*/
	
	.add_pay_method_modal {
		height: 26vh;
	}
	.d-none_md {
		display: none;
	}
}

@media (min-width: 768px) {
	.desktop-display {
		display: block;
	}
	.mobile-display {
		display: none;
	}
	
	div.card#products {
		.theme-revamp-table.table {
			table-layout: fixed; // only for subscription-details product and plans card
		}
	}
	
}
@media (max-width: 768px) {
	.blog-comments_detail__avatar {
		img.img-fluid {
			max-width: 90px;
			margin: 0 auto;
			display: block;
			margin-bottom: 10px;
		}
	}
	.desktop-display {
		display: none;
	}
	.mobile-display {
		display: block;
	}
	#view_list #sfListViewColWrapper .card {
		height: calc(100vh - 14.5rem);
	}
	#view_list #sfListViewColWrapper .table-responsive {
		max-height: calc(100vh - 25.1rem);
	}
	.d-none_xs {
		display: none;
	}
	li[data-sfuniqueid=navbar__additional_modules] .dropdown-menu .rounded-soft {
		max-height: 40vh;
	}
}

@media (max-width: 575px) {
	.btn.mr-2, .btn.mr-2~.btn {
		margin-bottom: 10px;
	}
	#gateway-config-area {
		.text-right {
			text-align: unset !important;
		}
	}
	
	.single-integration-wrapper {
		.single-integration-img-wrapper {
			padding: 5px;
		}
	}
	.content {
		padding-top: 4.3125rem !important;
	}
	#view_list .content.pb-5 {
		padding-bottom: 4.3125rem !important;
	}
	.d-none_xxs {
		display: none;
	}
}

@media screen and (min-width: 803px) {
	table.currencyTable{
	 table-layout: fixed;
	  width: 100%;
	  th.currencyTableColumnMiddle{
		width: 13% !important;
	  }
	  th.currencyTableColumnFirst{
		width: 10% !important;
	  }
	  th.currencyTableColumnLast{
		width: 25% !important;
	  }
	}
}
