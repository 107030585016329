.report-section {
	.nav {
		>li {
			>a {
				padding: 0;
				border-bottom: 1px dashed $gray-300;
				span {
					display: flex;
					align-items: center;
					svg.icons {
						width: 10px;
						height: 10px;
					}
					svg {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
}
.customize-reports {
	background: transparent !important;
}
.overflow-auto {
	overflow: auto !important;
}
.column {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	background: $white;
	z-index: 9;
	-webkit-animation: fadein .5s;
	animation: fadein .5s;
}
.rep-new-tab {
	position: relative;
	z-index: 4;
}
.close-details {
	float: right;
	padding-top: 8px;
	margin-left: 25px;
	cursor: pointer;
	color: #777;
}
svg.icons {
	width: 14px;
	height: 14px;
	margin-right: 6px;
	fill: currentcolor;
}
.criteria-wrapper.show {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.criteria-wrapper {
	background: $white;
	position: relative;
	z-index: 3;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: -webkit-transform .3s ease-out;
	transition: -webkit-transform .3s ease-out;
	-o-transition: transform .3s ease-out;
	transition: transform .3s ease-out;
	transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}
.criteria-row {
	background-color: $white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;
	.criteria-content {
		background-color: $white;
		width: 100% !important;
		padding: 15px;
		border-left: 1px solid $gray-300;
		border-right: 1px solid $gray-300;
	}
}
.col-form-label {
	padding-top: calc(.375rem + 1px);
	padding-bottom: calc(.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
	float: none;
	margin-top: 5px;
	padding-top: inherit;
	text-align: inherit;
	width: auto;
}
.criteria-actions {
	padding: 15px;
	border: 1px solid $gray-300;
	background: $white;
}

.mLbl {
	color: #b94a48;
	&:after {
		content: "*";
	}
}

.contom-view_x_arrows,.contom-view_y_arrows {
	display: flex;
	flex-direction: column;
}

