[class*="status-"] {
    color: #fff;
}
/* for subscription */
[class*="status-active"] {
    background-color: #30A860;
}
[class*="status-suspended"] {
    background-color: #FFA500;
}
[class*="status-cancelled"] {
    background-color: #800000;
}
[class*="status-expired"] {
    background-color: #000000;
}
[class*="status-pending"] {
    background-color: #f4c430;
}
[class*="status-draft"] {
    background-color: #939799;
}
[class*="status-trial"] {
    background-color: #0abab5;
}
[class*="status-failed"] {
    background-color: #FF0000;
}
[class*="status-declined"] {
    background-color: #b31b1b;
}
[class*="status-paid"] {
    background-color: #0b6623;
}

/* Credit Notes */
[class*="status-allocated"] {
    background-color: #000080;
}
[class*="status-unallocated"] {
    background-color: #5D8AA8;
}
[class*="status-partially-allocated"] {
    background-color: #4169e1;
}

/* Invoices */
[class*="status-partially-paid"] {
    background-color: #9ACD32;
}
[class*="status-due"] {
    background-color: #f4c430;
}
[class*="status-overdue"] {
    background-color: #f94d00;
}
[class*="status-void"] {
    background-color: #e5e4e2;
    color: #000000;
}
[class*="status-projected"] {
    background-color: #808000;
}