.navbar-vertical {
    z-index: 9 !important;
}

#theme-detail-view, .theme_side_nav {
    // nav.navbar.navbar-vertical.navbar-expand-xl.navbar-light.navbar-card {
    //     max-width: inherit !important;
    //     div#navbarVerticalCollapse {
    //         width: 90% !important;
    //         .navbar-vertical-content {
    //             width: 100% !important;
    //             height: $content_height;
    //         }
    //     }
    // }
}
.timeline_max_height {
    max-height: 60vh;
    overflow: auto;
}