
// MOdal
.modal {
    // z-index: 9999999 !important;
}
.modal-dialog {
    margin-top: 0 !important;
    & > i {
        display: none;
    }
    .modal-content.modal-content-set, .modal-content {
        margin-top: 0 !important;
        border-radius: 4px;
        // & > form {
        //     overflow: auto;
        // }
        .modal-body {
            & > .row {
                margin: 0;
            }
            .card {
                padding: 0;
            }
        }
        .modal-header {
            border-radius: 0;
            background-color: #2c7be5;
            .modal-title {
                color: #ffffff;
            }

            button.close {
                color: white;
            }
        }
    }
}
.modal-backdrop {
    &.show {
        opacity: 0.5 !important;
    }
}
.modal.show {
    background-color: #00000070;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}
.modal.fade.show:not(.modal-fixed-right) {
    .modal-body {
        max-height: calc(100vh - 11rem);
        overflow: auto !important;
    }
}
.modal-fixed-right .modal-content {
    min-height: 100vh;
}
body.modal-open {
    .select2-container--open {
        z-index: 1700;
    }
    .main-footer {
        z-index: 9 !important;
    }
    .modal {
        .modal-header:not(.modal-header.modal-header-settings) {
            button.close {
                -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                        order: 2;
            }
        }
    }
    .xdsoft_datetimepicker {
        z-index: 9999999 !important;
    }
}
// .modal-open .modal:not(.modal-fixed-right) {
//     max-height: calc(100vh - 1.3125rem);
// }
.modal-content.border-0.vh-100.scrollbar.perfect-scrollbar {
    max-height: calc(100vh - 1.3125rem);
}
