/* all icons position */
.timeline-entries {
	border-left: 1px solid #00AEFF;
}
.timeline-entry {
	padding: 0 15px;
	margin-bottom: 50px;
	&:before {
		content: '\25cf';
		position: absolute;
		top: -4.5px;
		left: -4.5px;
		z-index: 10;
		color: #00AEFF;
	}
	&:after {
		content: ' ';
		position: absolute;
		border: 1px solid #00AEFF;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		top: 0;
		left: -7px;
		background-color: #fff;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.timeline-title {
	font-style: italic;
	text-decoration: underline;
	margin-top: 10px;
}
.timeline-content {
	margin-top: 10px;
}
.timeline-time {
	line-height: 1;
	font-style: italic;
}
.pR {
	position: relative;
}
.posRel {
	position: relative;
}
.posrel {
	position: relative;
}
.timeLineHistInner {
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 7px;
		bottom: 0;
		left: 92px;
		width: 1px;
		background: #e3e3e3;
	}
}
.timeLineHistDate {
	color: #333;
	font-size: 13px;
	font-weight: normal;
	margin-left: 34px;
	background: #eee;
	padding: 5px 18px;
	line-height: 16px;
	z-index: 1;
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
	min-width: 88px;
	text-align: center;
}
.timeLineHistDataList {
	padding: 0 30px 0 0;
	margin: 0;
	list-style: none;
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: -7px;
		left: 92px;
		width: 1px;
		background: #e3e3e3;
	}
	li {
		padding: 0 0 20px;
		color: #111;
		background: none;
		margin-left: 0;
		>span {
			&:nth-child(2) {
				color: #555;
				margin-top: 0;
				padding-left: 4px;
				width: 700px !important;
			}
		}
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
}
.timeLineHistTime {
	padding-top: 10px;
	font-size: 13px;
	color: #555;
	padding-bottom: 5px;
	width: 117px;
	color: #9E9E9E;
	position: relative;
	text-align: center;
	padding-right: 50px;
	padding-top: 15px;
	font-weight: normal;
	&:after {
		top: 0;
		background: url('/assets/images/docs-images/icons/svgicons_all.svg') no-repeat;
		content: "";
		display: block;
		position: absolute;
		right: 10px;
		top: 8px;
		border-radius: 50%;
		background-position: center;
		background-color: #bebfbf;
		width: 30px;
		height: 30px;
		z-index: 19;
		visibility: visible;
	}
}
.timeline_added.Tasks {
	.timeLineHistTime {
		&:after {
			background-color: #bebfbf;
		}
	}
}
.timeline_TaskAssigned {
	.timeLineHistTime {
		&:after {
			background-color: #bebfbf;
		}
	}
}
.upcomingaction_tasks_added {
	.timeLineHistTime {
		&:after {
			background-color: #bebfbf;
		}
	}
}
.timeline_added {
	.timeLineHistTime {
		&:after {
			background-color: #bebfbf;
		}
	}
}
.tl_s {
	padding: 0 5px;
	color: $gray-700;
}
.DetailsPageRevampBox {
	.dvcolor_7 {
		color: $gray-700 !important;
		font-weight: 600;
	}
}
.tl_dat {
	color: $gray-700 !important;
	font-weight: 600;
}
.tl_by {
	color: $gray-700 !important;
	font-weight: 600;
}
.d-inline-b {
	display: inline-block;
}
.wallet-icon {
	&:after {
		background-position: -161px -1572px;
	}
}
.task-icon {
	&:after {
		background-position: -96px -1478px;
	}
}
.profile-edited-icon {
	&:after {
		background-position: -256px -1542px;
	}
}
@media screen and (max-width: 768px) {
	.timeLineHistInner {
		&:before {
			width: 0 !important;
		}
	}
	.timeLineHistDataList {
		&:before {
			width: 0 !important;
		}
		padding: 0;
		li {
			border-bottom: 1px solid $gray-300;
			>span {
				&:nth-child(2) {
					width: 100% !important;
				}
			}
		}
	}
	.timeLineHistDate {
		margin: 0;
		width: 100%;
	}
}
