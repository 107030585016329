
.btn {
    cursor: pointer;
}


.btn-outline-delete {
    color: $delete-btn;
    border-color: $delete-btn;

    &:hover {
        color: #fff;
        background-color: $delete-btn;
        border-color: $delete-btn;
    }
}


